import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { css, Styled } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children
}) => <>
    {children}
  </>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h3 {...{
      "id": "reading"
    }}>{`reading`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.goodreads.com/book/show/60631342-system-design-interview-an-insider-s-guide"
        }}>{`system design interview  vol 2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.goodreads.com/book/show/10127019-the-lean-startup"
        }}>{`the lean startup`}</a></li>
      <li parentName="ul">{`other `}<Styled.a as={Link} to="/notes/podcastsandnewsletters" css={css({
          textDecoration: `underline`
        })}>{`podcasts and newsletters`}</Styled.a></li>
    </ul>
    <h3 {...{
      "id": "notes"
    }}>{`notes`}</h3>
    <ul>
      <li parentName="ul">
        <Styled.a as={Link} to="/blog/coding-with-ai/" css={css({
          textDecoration: `underline`
        })}>how i code with ai</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="/blog/photosort/" css={css({
          textDecoration: `underline`
        })}>tiny photo organizer in go</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="/blog/medium" css={css({
          textDecoration: `underline`
        })}>things I wrote in medium</Styled.a>
      </li>
    </ul>
    <h3 {...{
      "id": "other"
    }}>{`other`}</h3>
    <ul>
      <li parentName="ul">
        <Styled.a as={Link} to="/notes/about" css={css({
          textDecoration: `underline`
        })}>/about</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="/notes/books" css={css({
          textDecoration: `underline`
        })}>/books</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="/notes/papers" css={css({
          textDecoration: `underline`
        })}>/papers</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="/photos" css={css({
          textDecoration: `underline`
        })}>/photos</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="/notes/certifications/" css={css({
          textDecoration: `underline`
        })}>/certifications</Styled.a>
      </li>
      <li parentName="ul">
        <Styled.a as={Link} to="https://aws-globe.pedro.fyi/" css={css({
          textDecoration: `underline`
        })}>/awsglobe</Styled.a>
      </li>
    </ul>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      